import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import classNames from "classnames";
import Faq from "./Faq";
import HowItWorks from "./HowItWorks";
import Guarantees from "./Guarantees";
import Confidentiality from "./Confidentiality";
import Offer from "./Offer";
import "./FaqWindow.css";

const FaqWindow = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [activeButton, setActiveButton] = useState(queryParams.get("section") || "faq");

  // Эффект для обновления состояния при изменении query-параметров
  useEffect(() => {
    const section = queryParams.get("section");
    if (section) {
      setActiveButton(section);
    }
  }, [location]);

  // Функция для отображения нужного компонента в зависимости от активной кнопки
  const renderContent = () => {
    switch (activeButton) {
      case "faq":
        return <Faq />;
      // case "howItWorks":
      //   return <HowItWorks />;
      // case "guarantees":
      //   return <Guarantees />;
      // case "confidentiality":
      //   return <Confidentiality />;
      case "offer":
        return <Offer />;
      default:
        return <Faq />;
    }
  };

  return (
    <div className="faq_window_container main-window-bg">
      <div className="faq_window_container-menu">
        <button
          className={classNames({ active: activeButton === "faq" })}
          onClick={() => setActiveButton("faq")}
        >
          Вопрос - Ответ
        </button>
        {/* <button
          className={classNames({ active: activeButton === "howItWorks" })}
          onClick={() => setActiveButton("howItWorks")}
        >
          Как это работает?
        </button>
        <button
          className={classNames({ active: activeButton === "guarantees" })}
          onClick={() => setActiveButton("guarantees")}
        >
          Гарантии
        </button>
        <button
          className={classNames({ active: activeButton === "confidentiality" })}
          onClick={() => setActiveButton("confidentiality")}
        >
          Конфиденциальность
        </button> */}
        <button
          className={classNames({ active: activeButton === "offer" })}
          onClick={() => setActiveButton("offer")}
        >
          Правила использования
        </button>
      </div>

      <div className="faq_window_container-help">
        <h3>Не нашли свой вопрос?</h3>
        <span>
          Вы можете обратиться в наш online чат, в котором вам помогут с вашим вопросом. Или напишите нам в Telegram
        </span>
        <button>Telegram</button>
      </div>

      <div className="faq_window_container-main">
        {renderContent()}
      </div>
    </div>
  );
};
export default FaqWindow;
