import "./FeedbackBlock.css";
import useFetchFeedbacks from "../../../hooks/feedback/useFetchFeedbacks.ts";
import StarFilled from "../../../assets/img/Star-filled.svg";
import StarNotFilled from "../../../assets/img/Star-notfilled.svg";
import { ItemBlock } from "../ItemBlock/ItemBlock.js";
import { useEffect } from "react";
import useWithMethodFetch from "../../../hooks/useWithMethodFetch.ts";

const FeedbackBlock = () => {
  // console.log(1);
  return (
    <div className="feedback_block">
      <div className="feedback_block__title">Что говорят о нас наши клиенты</div>
      <div className="feedback_block__description">
      Ниже представлены отзывы о нашем сайте с Trustpilot
      </div>
      <div className="feedback_block__list_of_blocks">
        <Carousel />
      </div>
      {/* <Link className="feedback_block__list_of_blocks-button" to="/feedback">
        Все отзывы
      </Link> */}
      <div className="feedback_block__right_block">
        <LastSells />
      </div>
    </div>
  );
};

const LastSells = () => {
  const skinTransactions = useWithMethodFetch('/api/transaction/last-success')

  useEffect(() => {
    skinTransactions.request({method: 'GET'});
    console.log(skinTransactions)
    const interval = setInterval(() => {
      skinTransactions.request({method: 'GET'});
    }, 10000); // 10000 миллисекунд = 10 секунд

    return () => clearInterval(interval);
  }, []);

  return <div className="lastSells_ontainer">
    <h2>Live лента продаж</h2>
    <div className="lastSells_ontainer-items">
      {skinTransactions?.data && skinTransactions.data.map(item => <ItemBlock item={item} howOld={true} />)}
    </div>
  </div>;
};

const Carousel = () => {
  const data = useFetchFeedbacks({ count: 3 });

  return (
    <div className="feedback_block__list_of_blocks_slider">
      {data.data &&
        data.data.map((v, index) => (
          <div
            key={index}
            className="feedback_block__list_of_blocks_slider_block"
          >
            <div className="feedback_block__list_of_blocks_slider_block-content">
              <img src={v.photo} alt={v.nickname} />
              <span>{v.nickname}</span>
              <p>{v.text}</p>
            </div>

            <div>
              {Array.from({ length: v.stars }, (_, i) => (
                <img key={`filled-${i}`} src={StarFilled} alt="Star" />
              ))}
              {Array.from({ length: 5 - v.stars }, (_, i) => (
                <img key={`not-filled-${i}`} src={StarNotFilled} alt="Star" />
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default FeedbackBlock;
